import React from 'react'

function Mappage() {
  return (
    <div>
         <div className="album py-2">
          <div className="container">
            <div className="row">
              <iframe className="iframe-fluid mb-3" width="520" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=DESERTMINA,%2054XJ+M2C,%20NH114A,%20Dakshin%20Gram,%20West%20Bengal%20742184%20+(DESERTMINA,%2054XJ+M2C,%20NH114A,%20Dakshin%20Gram,%20West%20Bengal%20742184)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
              <iframe className="iframe-fluid mb-2" src="https://www.google.com/maps/embed?pb=!4v1733247776429!6m8!1m7!1sga12eUfFhR2SZ84sxYUJjA!2m2!1d24.19986023568803!2d88.1309585313699!3f254.9613604661984!4f-1.696775518731954!5f2.4052225632212765" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div> 
    </div>
  )
}

export default Mappage