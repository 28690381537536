import React from 'react'
import Navbar from '../inc/Navbar'
import ReactPlayer from "react-player";
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';

import banner from "../images/final home.mp4"
import "./Home.css";
// import Slider from '../inc/Slider';
import ourcompany from "../images/tag new.jpg";

import ScrollToTop from "react-scroll-to-top";
import liter1 from "../images/1 liter mineral.jpg";
import ml500 from "../images/500 ml.jpg"
import clubshoda from "../images/club shoda.jpg";
import liter2 from "../images/2 liter.jpg";
import liter20 from "../images/20 liter design.jpg"
import liter5 from "../images/5liter.jpg";
import ReactWhatsapp from "react-whatsapp";

import doctoricon from "../images/doctor icons.png"

function Home() {
  return (
    <div>

      <Navbar />

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <Video autoPlay muted loop onCanPlayThrough={() => {
        console.log('video play')
      }}>
        <source src={banner} height="1400px" type="video/webm" />
      </Video>

      <br />
      {/* <Slider /> */}
      <div className="album py-3">
        <div className="container">
          <div className="row">
            <div className="newradi">
              <ReactPlayer width="100%" height="100%" url="https://www.youtube.com/watch?v=TTT1vRv-I8w&t=10s" />
            </div>
          </div>
        </div>
      </div>


      <div style={{ color: "Green", display: 'flex', justifyContent: 'flex-end' }} className="fixed-bottom  mb-1"> <ReactWhatsapp number="+91  9434442245" className="circleb" message="I am Interested"> <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
        <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
      </svg>   </ReactWhatsapp></div>



      <ScrollToTop smooth top="100" color="darkBlue" />
      <center>
        <h1 style={{ color: "darkBlue" }}><b>OUR PRODUTS</b></h1>
      </center>


      <div className="album py-2">
        <div className="container">
          <div className="row">
            <div className="col-md-3 mt-3">

              <div className="card">
                <img src={ml500} className="card-img-top" alt="500ml" />
                <div className='textnewstyle'>
                  <div className="card-body">

                    <h5 className="card-title">500 ML</h5>
                    <p className="card-text"> Perfect for when you're on the go</p>
                    <a href="tel: 9434442245" className="productsbutton">Order Now <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                      <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                    </svg></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mt-3">

              <div className="card">
                <img src={liter1} className="card-img-top" alt="desertmina"/>
                <div className='textnewstyle'>
                  <div className="card-body">
                    <h5 className="card-title">1 Liter</h5>
                    <p className="card-text"> Great for personal hydration throughout the day</p>
                    <a href="tel: 9434442245" className="productsbutton">Order Now <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                      <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                    </svg></a>
                  </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 mt-3">

                <div className="card">
                  <img src={liter2} className="card-img-top" alt="2liter" />
                  <div className='textnewstyle'>
                  <div className="card-body">
                    <h5 className="card-title">2 Liter</h5>
                    <p className="card-text">Ideal for families or longer outings.</p>
                    <a href="tel: 9434442245" className="productsbutton">Order Now <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                      <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                    </svg></a>
                  </div>
                  </div>
                </div>

              </div>
              <div className="col-md-3 mt-3">

                <div className="card">
                  <img src={liter5} className="card-img-top" alt="5liter" />
                  <div className='textnewstyle'>
                  <div className="card-body">
                    <h5 className="card-title">5 Liter</h5>
                    <p className="card-text">Handy for small gatherings or home use.</p>
                    <a href="tel: 9434442245" className="productsbutton">Order Now <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                      <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                    </svg></a>
                  </div>
                  </div>

                </div>

              </div>
              <div className="col-md-3 mt-3">

                <div className="card">
                  <img src={liter20} className="card-img-top" alt="20litr" />
                  <div className='textnewstyle'>
                  <div className="card-body">
                    <h5 className="card-title">20 Liter</h5>
                    <p className="card-text">Suitable for larger households or events, ensuring a steady supply of safe
                      drinking water.</p>
                    <a href="tel: 9434442245" className="productsbutton">Order Now <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                      <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                    </svg></a>
                  </div>
                  </div>
                </div>
              </div>


              <div className="col-md-3 mt-3">
                <div className="card">
                  <img src={clubshoda} className="card-img-top" alt="clubshoda"/>
                  <div className='textnewstyle'>
                  <div className="card-body">
                    <h5 className="card-title">600 ML</h5>
                    <p className="card-text">Soda</p>
                    <a href="tel: 9434442245" className="productsbutton">Order Now <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                      <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                    </svg></a>
                  </div>
                  </div>
                </div>


              </div>

            </div>
          </div>

        </div>

        <br />
        <br />
        <div className="album py-1">
          <div className="container">
            <center>
              <h1 className='mt-2' style={{ color: "darkBlue" }}><b>OUR COMPANY </b></h1>
            </center>
          </div>
        </div>

        <div className="album py-3">
          <div className="container">
            <div className="row">
              <div className="col-md-6 mt-3">
                <div className="card-body">
                  <h5 className="card-title  mb-3">D.D INDUSTRIES: A STATE OF THE ART MANUFACTURING UNIT OF PACKAGED DRINKING WATER.</h5>
                  <p className="card-text ">An ISO 9001: 2015 Certified Company </p>
                  <h6 className="card-text ">APPROVED BY:
                    BUREAU OF INDIAN STANDARDS(BIS) & FOOD SAFETY STANDARDS AUTHORITY OF INDIA (FSSAI) </h6>
                  <p className="card-text">                                Our multistage stringent purification process includes ACF, DMF, Reverse Osmosis, U.V Treatment,Micron Filtration,Ozonization, Mineralization & strong quality control which assures that every bottle of water we provide is pure, clean, and meets the highest safety standards.  We take pride in offering water that meets the highest standards of purity and taste, giving you the confidence that every bottle is a refreshing and rejuvenating choice. Stay refreshed, stay rejuvenated & Stay hydrated by giving a hand to Desertmina .
                  </p>
                </div>
              </div>
              <div className="col-md-6 mt-3">
                <img src={ourcompany} className="card-img-top" alt="desertmina" />

              </div>
            </div>
          </div>
        </div>
        <br />


        <div className="album py-1">
          <div className="container">
            <center>
              <h1 className='mt-2' style={{ color: "darkBlue" }}><b>Why You should Choose Desertmina? </b></h1>
            </center>
          </div>
        </div>

        <div className="album py-2">
          <div className="container">
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    1. Quality You Can Trust
                  </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <h6 className='justify'> Every drop of Desertmina water is sourced from trusted water sources and goes through multiple
                      levels of filtration and purification, including reverse osmosis, Ozonization by Oxygen Concentrator, Micron Filtration to eliminate harmful contaminants. Our goal is to provide you water that meets the highest quality and safety standards.
                    </h6>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    2. Zero Contamination
                  </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                  <div className="accordion-body">

                    <h6>Our water is tested for microbial, chemical, and physical contaminants, ensuring that you receive only the safest, freshest water.</h6>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    3. Rigorous Purification Procedure
                  </button>
                </h2>
                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <h6 className='justify'>The execution of stringent purification & filtration adhere the highest standard of packaged drinking water. The water enters into a UV chamber & gets exposed to UV light which disinfect water by altering the DNA of microorganisms in the water. Every drop of Desertmina is fully ozonized to eliminate harmful impurities. We also implement Micron Filtration to remove suspended particles & microorganisms. The application of Reverse Osmosis separates the water molecules from other substances & applies pressure to overcome osmotic pressure that favors  even distribution.</h6>
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFour">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    4. World Class Treatment plant
                  </button>
                </h2>
                <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <h6 className='justify'>The execution of stringent purification & filtration adhere the highest standard of packaged drinking water. The water enters into a UV chamber & gets exposed to UV light which disinfect water by altering the DNA of microorganisms in the water. Every drop of Desertmina is fully ozonized to eliminate harmful impurities. We also implement Micron Filtration to remove suspended particles & microorganisms. The application of Reverse Osmosis separates the water molecules from other substances & applies pressure to overcome osmotic pressure that favors  even distribution.</h6>
                  </div>
                </div>
              </div>


              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFive">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                    5. Balanced Mineral Content
                  </button>
                </h2>
                <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                  <div className="accordion-body">
                    <h6 className='justify'> Desertmina water is not just clean but also healthful. It contains essential minerals like calcium and magnesium in balanced amounts, contributing to your overall well-being. Our mineral composition ensures that you get the hydration you need without compromising on health benefits.</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



        <br />
        <br />


        <div className="album py-1">
          <div className="container">
            <center>
              <h1 className='mt-2' style={{ color: "darkBlue" }}><b>Chairman's Desk </b></h1>
            </center>
          </div>
        </div>

        <div className="album py-3">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mt-3">
                <div className="card-body">
                  <h6 className="card-title mb-3">In the year 2008,Our Chairman, Shri Prodyot Kumar Datta dreamed of a manufacturing project regarding the consciousness of human health.  15 years ago when it was not usual to buy and drink water in the streets of Bengal, Our chairman stood and realized that one day, not only the city, but also the people of the streets and villages will have to buy and drink pure drinking water in order to stay healthy.</h6>

                  <h6 className="card-text ">And today that thought of Prodyot Datta has become true in the whole of India.The dream of a rural Bengali person  is spreading like a wave to every corner of Murshidabad & surrounding districts. Now With a multitude of experiences, Desertmina has become epitome of best packaged drinking water in the whole Bengal.Our Mission is always:Delivering Safe and Clean Water. Desertmina is dedicated to ensuring that every bottle of water we provide is pure, clean, and meets the highest safety standards. We follow stringent processes to ensure that our water is not just safe, but also rich in essential minerals, offering you the most wholesome hydration experience possible. </h6>

                </div>
              </div>

            </div>
          </div>
        </div>
        <br />



        <div className='deskcolor'>
          <div className="album py-1">
            <div className="container">

              <h1 className='mt-2 mb-2' style={{ color: "darkBlue" }}><b>Doctor’s Desk   <img src={doctoricon} height="70px" width="70px" alt="doctoricon" />
              </b></h1>
            </div>
          </div>
        </div>

        <div className="album py-3">
          <div className="container">
            <h5>Daily Water Requirement for Adults*</h5>
            <p>
              The daily water intake varies based on several factors, but general recommendations for total water consumption, including water from all beverages and food, are:
            </p>
            <h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-all" viewBox="0 0 16 16">
              <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
              <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
            </svg> Men: Approximately 2.5 to 3.7 liters per day (WHO recommends 2.9 liters in moderate climates).</h6>
            <h6 className='mb-3'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-all" viewBox="0 0 16 16">
              <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
              <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
            </svg> Women: Approximately 2 to 2.7 liters per day (WHO recommends 2.2 liters in moderate climates).</h6>

            <p className='justify'>These estimates can increase in hot climates, during physical activity, or when an individual is ill (e.g., with fever or diarrhea). WHO emphasizes that fluid intake should compensate for water loss through sweat, urine, and other bodily functions.</p>

            <h6><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-all" viewBox="0 0 16 16">
              <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
              <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
            </svg> Blood: Composed of about 90% water, essential for transporting nutrients and oxygen.</h6>
            <h6 className='mb-3'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-all" viewBox="0 0 16 16">
              <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
              <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
            </svg> Brain and heart: Approximately 73% water, crucial for cognitive function and cardiovascular health.</h6>
            <p>WHO emphasizes the importance of maintaining adequate hydration as water is critical for numerous bodily functions, including digestion, temperature regulation, and nutrient transportation.</p>

            <h5 style={{ color: "darkBlue" }} className='justify'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style={{ color: "darkBlue" }} fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg> Hydration is the key to a healthy and happy life & Water  is ‘The secret ingredient’ to unlock your true potential. The manufacturing unit of Desertmina processes the water through rigorous purification & filtration process, retaining beneficial minerals while eradicating harmful impurities which can provide you the safest & cleanest form of drinking water. It also contains essential minerals in just the right amounts to contribute to your daily nutritional needs. Stay hyadratd & healthy by preferring Desertmina : Your Trusted Source for Safe and Wholesome Packaged Drinking Water.</h5>
          </div>
        </div>

        <br />
        <br />







      
        <br />
      </div>
      )
}

      export default Home