import React from 'react'
import Navbar from '../inc/Navbar'
import ScrollToTop from "react-scroll-to-top";

function Commentary() {
  return (
    <div>
      <Navbar />
      <br />
      <br />
      <br />

      <br></br>
      <ScrollToTop smooth top="100" color="darkBlue" />
      <div className='desertmian'>
        <div className="album py-2">
          <div className="container mt-4">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="/">Home</a></li>
                <li className="breadcrumb-item active" aria-current="page">Commentary</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="album py-3">
        <div className="container">
          <h6 className='justify mb-4'>  At Desertmina, we understand the importance of providing water, that is not only refreshing but also
            safe from contaminants. Our rigorous safety measures and adherence to strict quality standards
            ensure that the water you consume is free from harmful pathogens, chemicals, and impurities.
            Key Criteria for Safe Drinking Water:</h6>
          <h5 style={{ color: "darkBlue" }}><b>1. Microbial Safety</b></h5>
          <p className='justify'>Our water undergoes disinfection to eliminate harmful bacteria, viruses, and parasites, preventing
            diseases like cholera, typhoid, and dysentery. The safety of your drinking water is our top priority,
            and we ensure that every batch is free from pathogens.
          </p>

          <h5 style={{ color: "darkBlue" }}><b>2. Chemical Safety </b></h5>
          <p className='justify'> We carefully monitor the chemical composition of our water to ensure it is free from hazardous
            chemicals like heavy metals (lead, mercury, arsenic) and industrial pollutants. Our testing procedures
            are designed to safeguard you against chemical contamination, providing peace of mind with every
            sip.
          </p>

          <h5 style={{ color: "darkBlue" }}><b>3. Physical Quality </b></h5>
          <p className='justify'> Clear, clean, and refreshing—that's the standard for Desertmina water. We guarantee that our
            water is free from turbidity (cloudiness) and other visible contaminants, ensuring a pure and
            pleasant drinking experience
          </p>

          <h5 style={{ color: "darkBlue" }}><b>4. pH Balance and Mineral Content </b></h5>
          <p className='justify'>Desertmina water is pH-balanced, falling within the optimal range of 6.5 to 8.5 to support your
            body’s natural processes. In addition, our water contains essential minerals in just the right amounts
            to contribute to your daily nutritional needs.
          </p>
          <br />
          <h6><b>Preventing Waterborne Diseases</b></h6>
          <p>Waterborne diseases are caused by drinking or coming into contact with water contaminated by
            harmful microorganisms such as bacteria, viruses, parasites, and chemicals. These diseases are a
            major health concern, particularly in areas with inadequate sanitation and water treatment. Below
            are the most common waterborne diseases, categorized by their cause
          </p>




          <div className="album py-2">
            <div className="container">
              <div className="row">
                <div className="col-md-3 mt-3">
                  <h6><b>Bacterial Diseases:</b></h6>
                  <p className='mb-1' style={{ color: "darkBlue" }}> <small><svg xmlns="http://www.w3.org/2000/svg" style={{ color: "darkBlue" }} width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill me-2" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg> Cholera</small></p>
                  <p className='mb-1' style={{ color: "darkBlue" }}><small><svg xmlns="http://www.w3.org/2000/svg" style={{ color: "darkBlue" }} width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill me-2" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg> Typhoid Fever</small></p>

                  <p className='mb-1' style={{ color: "darkBlue" }}><small><svg xmlns="http://www.w3.org/2000/svg" style={{ color: "darkBlue" }} width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill me-2" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg> Dysentery (caused by Shigella)</small></p>
                  <p className='mb-1' style={{ color: "darkBlue" }}><small><svg xmlns="http://www.w3.org/2000/svg" style={{ color: "darkBlue" }} width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill me-2" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg> Leptospirosis</small></p>
                  <p className='mb-4' style={{ color: "darkBlue" }}><small><svg xmlns="http://www.w3.org/2000/svg" style={{ color: "darkBlue" }} width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill me-2" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg> Escherichia coli (E. coli) infection</small></p>

                </div>
                <div className="col-md-3 mt-3">
                  <h6><b>Viral Diseases:</b></h6>
                  <p className='mb-1' style={{ color: "darkBlue" }}> <small><svg xmlns="http://www.w3.org/2000/svg" style={{ color: "darkBlue" }} width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill me-2" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg> Hepatitis A</small></p>
                  <p className='mb-1' style={{ color: "darkBlue" }}><small><svg xmlns="http://www.w3.org/2000/svg" style={{ color: "darkBlue" }} width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill me-2" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg> Rotavirus infection</small></p>

                  <p className='mb-1' style={{ color: "darkBlue" }}><small><svg xmlns="http://www.w3.org/2000/svg" style={{ color: "darkBlue" }} width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill me-2" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg> Norovirus infection</small></p>
                  <p className='mb-4' style={{ color: "darkBlue" }}><small><svg xmlns="http://www.w3.org/2000/svg" style={{ color: "darkBlue" }} width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill me-2" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg> Poliomyelitis (Polio)</small></p>
                </div>
                <div className="col-md-3 mt-3">
                  <h6><b>Parasitic Diseases:</b></h6>
                  <p className='mb-1' style={{ color: "darkBlue" }}> <small><svg xmlns="http://www.w3.org/2000/svg" style={{ color: "darkBlue" }} width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill me-2" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg> Giardiasis</small></p>
                  <p className='mb-1' style={{ color: "darkBlue" }}><small><svg xmlns="http://www.w3.org/2000/svg" style={{ color: "darkBlue" }} width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill me-2" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg> Amoebiasis (caused by Entamoeba histolytica)</small></p>

                  <p className='mb-1' style={{ color: "darkBlue" }}><small><svg xmlns="http://www.w3.org/2000/svg" style={{ color: "darkBlue" }} width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill me-2" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg> Cryptosporidiosis</small></p>
                  <p className='mb-1' style={{ color: "darkBlue" }}><small><svg xmlns="http://www.w3.org/2000/svg" style={{ color: "darkBlue" }} width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill me-2" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg> Schistosomiasis</small></p>
                </div>

                <div className="col-md-3 mt-3">
                  <h6><b>Protozoal Diseases:</b></h6>
                  <p className='mb-1' style={{ color: "darkBlue" }}> <small><svg xmlns="http://www.w3.org/2000/svg" style={{ color: "darkBlue" }} width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill me-2" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg> Balantidiasis</small></p>
                  <p className='mb-1' style={{ color: "darkBlue" }}><small><svg xmlns="http://www.w3.org/2000/svg" style={{ color: "darkBlue" }} width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill me-2" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                  </svg> Toxoplasmosis</small></p>

           
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <br />

    </div>
  )
}

export default Commentary